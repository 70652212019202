import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, List, Typography, InputNumber, Form, Input, Row, Col, Divider } from 'antd';
import { RootState } from '../store/store';
import { updateItemQuantity, removeItemFromCart } from '../store/cartSlice';
import { updateUserDetails } from '../store/userDetailsSlice';
import { useNavigate } from 'react-router-dom';
import type { FormProps } from 'antd';
import { UserDetails } from '../models';

const { Title, Text } = Typography;

interface CartPageProps {
  isCustomDonation: boolean;
  customDonation: number;
}

const Cart: React.FC<CartPageProps> = ({ isCustomDonation, customDonation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItems = useSelector((state: RootState) => state.cart.items);
  const userDetails = useSelector((state: RootState) => state.userDetails.userDetails);

  const updateCartItemQuantity = (itemId: number, quantity: number) => {
    dispatch(updateItemQuantity({ id: itemId, quantity }));
  };

  const handleRemoveItem = (itemId: number) => {
    dispatch(removeItemFromCart(itemId));
  };

  const onFinish: FormProps<UserDetails>['onFinish'] = (values: UserDetails) => {
    dispatch(updateUserDetails(values));
  };

  const handleInputChange = (field: keyof UserDetails, value: string) => {
    dispatch(updateUserDetails({ ...userDetails, [field]: value }));
  };

  const totalAmount = isCustomDonation
    ? customDonation
    : cartItems.reduce((total, item) => total + item.unitPrice * (item.quantity ?? 0), 0);

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
        Shopping Cart
      </Title>
      {cartItems && cartItems.length > 0 && (
        <Card bordered style={{ marginBottom: '24px' }}>
          <List
            itemLayout="horizontal"
            dataSource={cartItems}
            renderItem={(item) => (
              <List.Item>
                <Row style={{ width: '100%' }}>
                  <Col xs={24} sm={12}>
                    <List.Item.Meta
                      title={<Text strong>{item.name}</Text>}
                      description={<Text>Unit Price: ${item.unitPrice.toFixed(2)}</Text>}
                    />
                  </Col>
                  <Col xs={24} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Row gutter={12}>
                      <Col span={10}>
                        <Text>Quantity:</Text>
                      </Col>
                      <Col span={14}>
                        <InputNumber
                          min={1}
                          max={item.stock}
                          value={item?.quantity || 0}
                          onChange={(value) => updateCartItemQuantity(item.id, value as number)}
                          style={{ width: '100%' }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Text>Total: ${item.unitPrice * (item?.quantity ?? 0)}</Text>
                  </Col>
                  <Col xs={24} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button type="link" danger onClick={() => handleRemoveItem(item.id)}>
                      Remove
                    </Button>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
          <Divider />
          <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Donation: ${totalAmount.toFixed(2)}</Text>
        </Card>
      )}

      {isCustomDonation && (
        <Card bordered style={{ marginBottom: '24px' }}>
          <Title level={4}>Custom Donation</Title>
          <Text style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Donation: ${customDonation.toFixed(2)}</Text>
        </Card>
      )}

      <Card bordered style={{ marginBottom: '24px' }}>
        <Title level={4}>Donor Details</Title>
        <Form
          labelCol={{ span: 32 }}
          wrapperCol={{ span: 32 }}
          style={{ maxWidth: 600, boxShadow: 'none' }}
          name="basic"
          layout="vertical"
          initialValues={userDetails}
          onFinish={onFinish}
          autoComplete="on">
          <Row gutter={12}>
            <Col span={11}>
              <Form.Item label="First Name" name="firstName" required>
                <Input value={userDetails.firstName} onChange={(e) => handleInputChange('firstName', e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Last Name" name="lastName" required>
                <Input value={userDetails.lastName} onChange={(e) => handleInputChange('lastName', e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={20}>
              <Form.Item label="Email" name="email" required>
                <Input value={userDetails.email} onChange={(e) => handleInputChange('email', e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={20}>
              <Form.Item label="Phone Number" name="phone" required>
                <Input value={userDetails.phone} onChange={(e) => handleInputChange('phone', e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={20}>
              <Form.Item label="Address 1" name="address" required>
                <Input value={userDetails.address} onChange={(e) => handleInputChange('address', e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={20}>
              <Form.Item label="Address 2" name="address2">
                <Input value={userDetails.address2} onChange={(e) => handleInputChange('address2', e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <Form.Item label="City" name="city" required>
                <Input value={userDetails.city} onChange={(e) => handleInputChange('city', e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Province" name="province" required>
                <Input value={userDetails.province} onChange={(e) => handleInputChange('province', e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Postal Code" name="postalCode" required>
                <Input
                  value={userDetails.postalCode}
                  onChange={(e) => handleInputChange('postalCode', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Country" name="country" required>
                <Input value={userDetails.country} onChange={(e) => handleInputChange('country', e.target.value)} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Button
        type="primary"
        onClick={() => navigate('/checkout')}
        style={{ width: '100%', padding: '12px', fontSize: '16px', fontWeight: 'bold' }}>
        Proceed to Checkout
      </Button>
    </div>
  );
};

export default Cart;
