// src/store/store.ts
import { combineReducers, configureStore, ThunkMiddleware, Tuple } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import { productsApiSlice } from './productApiSlice';
import userDetailsSlice from './userDetailsSlice';

const rootReducer = combineReducers({
  productsApi: productsApiSlice.reducer,
  cart: cartReducer,
  userDetails: userDetailsSlice,
});

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>;

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    // Add the RTK Query API middleware
    middleware: (getDefaultMiddleware): Tuple<ThunkMiddleware[]> => {
      return getDefaultMiddleware().concat(productsApiSlice.middleware);
    },
    preloadedState,
  });
  return store;
};
export const store = makeStore();

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
