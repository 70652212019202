import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import './index.css';

import Checkout from './pages/Checkout';
import CompletePage from './pages/CompletePage';
import ProductPage from './pages/ProductPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Layout, Typography, Image } from 'antd';
import { RootState } from './store/store';
import { useSelector } from 'react-redux';
import Cart from './pages/Cart';
import { initiateCustomDonationLibrary, initiateDonationLibrary } from './library/payments.library';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

export default function App() {
  const [clientSecret, setClientSecret] = useState<string | ''>('');
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [isCustomDonation, setIsCustomDonation] = useState<boolean>(false);
  const [customDonation, setCustomDonation] = useState<number>(5);

  useEffect(() => {
    if (cartItems.length === 0) return;

    const initiateDonation = async () => {
      try {
        const data = await initiateDonationLibrary(cartItems);
        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        }
      } catch (error) {
        console.error('Error initiating donation:', error);
      }
    };

    initiateDonation();
  }, [cartItems]);

  useEffect(() => {
    if (isCustomDonation) {
      const initiateDonation = async () => {
        try {
          const data = await initiateCustomDonationLibrary(customDonation);
          if (data.clientSecret) {
            setClientSecret(data.clientSecret);
          }
        } catch (error) {
          console.error('Error initiating donation:', error);
        }
      };
      initiateDonation();
    }
  }, [isCustomDonation, customDonation, cartItems]);

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Header className="header">
            <div className="header-div">
              <Image width={80} height={80} src="/logo192.png" alt="Mosque Logo" preview={false} />
              <div>
                <Title level={2} className="header-title" style={{ color: '#ffffff' }}>
                  {process.env.REACT_APP_SITE_NAME || ''}
                </Title>
                <Text className="header-text" style={{ color: '#ffffff' }}>
                  {process.env.REACT_APP_SITE_DESCRIPTION || ''}
                </Text>
              </div>
            </div>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Elements stripe={stripePromise}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProductPage setIsCustomDonation={setIsCustomDonation} setCustomDonation={setCustomDonation} />
                  }
                />
                <Route
                  path="/cart"
                  element={<Cart isCustomDonation={isCustomDonation} customDonation={customDonation} />}
                />
                <Route path="/checkout" element={<Checkout clientSecret={clientSecret} />} />
                <Route path="/complete" element={<CompletePage />} />
              </Routes>
            </Elements>
          </Content>
          <Footer style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>
              Mosqueify © <b>&nbsp;{new Date().getFullYear()}</b>
            </div>
            <div style={{ fontSize: '15px', display: 'flex', justifyContent: 'center' }}>
              Developed by&nbsp;
              <a href="https://habibrahman.me" target="_blank" rel="noreferrer">
                {' '}
                Habib Rahman{' '}
              </a>
              &nbsp;at&nbsp;
              <a href="https://mlify.ca" target="_blank" rel="noreferrer">
                MLify Inc.
              </a>
            </div>
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
}
