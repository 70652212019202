import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from '../pages/CheckoutForm';
import CompletePage from '../pages/CompletePage';

const Checkout: React.FC<{ clientSecret: string }> = ({ clientSecret }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';
  return (
    <div className="flex container mt-8">
      {clientSecret.length > 0 && (
        <Elements stripe={stripePromise} options={{ clientSecret, loader }}>
          <CheckoutForm />
          <CompletePage />
        </Elements>
      )}
    </div>
  );
};

export default Checkout;
