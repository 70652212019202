import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails } from '../models';

interface UserDetailsState {
  userDetails: UserDetails;
}

const initialState: UserDetailsState = {
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    province: '',
    country: '',
    phone: '',
  },
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    addUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    updateUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    removeUserDetails: (state) => {
      state.userDetails = initialState.userDetails;
    },
  },
});

export const { setUserDetails, addUserDetails, updateUserDetails, removeUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
