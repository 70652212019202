import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../models';

interface CartState {
  items: Product[];
}

const initialState: CartState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems: (state, action: PayloadAction<Product[]>) => {
      state.items = action.payload;
    },
    addItemToCart: (state, action: PayloadAction<Product>) => {
      const item = state.items.find((i) => i.id === action.payload.id);
      if (item) {
        if (item.quantity !== undefined) {
          item.quantity += action.payload.quantity || 0;
        } else {
          item.quantity = action.payload.quantity;
        }
      } else {
        state.items.push(action.payload);
      }
    },
    updateItemQuantity: (state, action: PayloadAction<{ id: number; quantity: number }>) => {
      const item = state.items.find((i) => i.id === action.payload.id);
      if (item) {
        item.quantity = action.payload.quantity;
      }
    },
    removeItemFromCart: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
  },
});

export const { setCartItems, addItemToCart, updateItemQuantity, removeItemFromCart } = cartSlice.actions;
export default cartSlice.reducer;
