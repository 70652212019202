// Type: Library
import { Product, TransactionDetails } from '../models';

// Description: This library contains the function that initiates the donation.
export const initiateDonationLibrary = async (cartItems: Product[]) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/purchase`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(cartItems),
  });
  return await response.json();
};

export const initiateCustomDonationLibrary = async (customDonation: number | null) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/donate`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ amount: customDonation }),
  });
  return await response.json();
};

export const recordTransactionLibrary = async (transaction: TransactionDetails) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/transactions`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(transaction),
  });
  return await response.json();
};
