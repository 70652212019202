import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Typography, Badge, InputNumber } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { addItemToCart, removeItemFromCart } from '../store/cartSlice';
import { useGetProductsQuery } from '../store/productApiSlice';
import { Product } from '../models';
import { RootState } from '../store/store';

const { Title, Text } = Typography;

interface ProductPageProps {
  setIsCustomDonation: (value: boolean) => void;
  setCustomDonation: (value: number) => void;
}

const ProductPage: React.FC<ProductPageProps> = ({ setIsCustomDonation, setCustomDonation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: products = [] } = useGetProductsQuery();
  const [currentQuantity, setCurrentQuantity] = React.useState(1);
  const [intendedDonation, setIntendedDonation] = React.useState(5);
  const cartItems = useSelector((state: RootState) => state.cart.items);

  const addToCart = (item: Product, unit: any) => {
    setIsCustomDonation(false);
    dispatch(addItemToCart({ ...item, quantity: unit })); // Adding item with quantity 1
  };

  const DonateNow = () => {
    cartItems.forEach((item) => dispatch(removeItemFromCart(item.id)));
    setCustomDonation(intendedDonation);
    setIsCustomDonation(true);
    navigate('/cart');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px 20px 0 0' }}>
        <Card bordered={false} style={{ maxWidth: '600px', margin: '0 auto' }}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
            Onetime Donation for Capital Projects
          </Title>
          <form>
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Amount:</Text>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name="donationAmount" value="100" onChange={() => setIntendedDonation(100)} />
                <Text> $100 </Text>
              </label>
              <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name="donationAmount" value="200" onChange={() => setIntendedDonation(200)} />
                <Text> $200 </Text>
              </label>
              <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name="donationAmount" value="300" onChange={() => setIntendedDonation(300)} />
                <Text> $300 </Text>
              </label>
              <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name="donationAmount" value="other" onChange={() => setIntendedDonation(5)} />
                <InputNumber min={1} defaultValue={5} onChange={(value) => setIntendedDonation(Number(value))} />
              </label>
            </div>
            <Button type="primary" onClick={(e) => DonateNow()} style={{ marginTop: '20px', width: '100%' }}>
              Donate Now
            </Button>
          </form>
        </Card>
      </div>
      <div style={{ padding: '20px' }}>
        <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
          Capital Projects Donation: Select products to donate
        </Title>
        <Row gutter={[16, 16]}>
          {products.map(
            (item) =>
              item.stock > 0 && (
                <Col xs={24} sm={12} md={8} lg={6} key={item.id}>
                  <Card hoverable title={item.name} bordered={false} style={{ width: '100%', textAlign: 'center' }}>
                    <Text>Quantity Available: {item.stock}</Text>
                    <br />
                    <Text strong>Unit Price: ${item.unitPrice.toFixed(2)}</Text>
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                      <InputNumber
                        min={1}
                        max={item.stock}
                        defaultValue={1}
                        onChange={(value) => setCurrentQuantity(Number(value))}
                      />
                      <Button type="primary" onClick={(e) => addToCart(item, currentQuantity)}>
                        Add to Cart
                      </Button>
                    </div>
                  </Card>
                </Col>
              ),
          )}
        </Row>
        {/* Cart Summary Button */}
        <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
          <Badge count={cartItems.reduce((total, item) => total + (item?.quantity ?? 0), 0)} showZero>
            <Button type="primary" icon={<ShoppingCartOutlined />} onClick={() => navigate('/cart')} size="large">
              {window.innerWidth < 768 ? '' : 'View Cart'}
            </Button>
          </Badge>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
