import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const CheckoutForm = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector((state: RootState) => state.userDetails.userDetails);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      // Show error to your customer
      setMessage(submitError.message);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        receipt_email: userDetails.email,
      },
      redirect: 'if_required',
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message ?? 'An error occurred');
      } else {
        setMessage('An unexpected error occurred.');
      }
    } else if (paymentIntent) {
      // Extract details from the paymentIntent object
      const paymentIntentId = paymentIntent.id;
      const paymentStatus = paymentIntent.status;

      navigate(
        `/complete?payment_intent=${paymentIntentId}&payment_intent_client_secret=${paymentIntent.client_secret}&redirect_status=${paymentStatus}`,
      );
    }

    setIsLoading(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
        <AddressElement
          options={{
            mode: 'shipping',
            autocomplete: {
              mode: 'automatic', // Set to 'automatic' for inline autocomplete
            },
          }}
          onChange={(event) => {
            if (event.complete) {
              setMessage('Address completed.');
            } else {
              setMessage('Please fill out all address fields.');
            }
          }}
        />
        <PaymentElement id="payment-element" />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}</span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
};
